import axios from "axios";
import { environment } from "../environments/environment";

export default class UserService {
  getMyInfos() {
    return axios.get(environment.API_URL + `users/me`);
  }
  updateMyInfos(data) {
    return axios.post(environment.API_URL + `users/me`, data);
  }
}
